import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import routesAdmin from "routes.js";
import routesTailor from "routesTailor.js";
import routesHairdress from "routesHairdress.js";
import routesBlogger from "routesBlogger.js";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkTokenNow } from "redux/apiCalls";

const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    checkTokenNow(dispatch);
  }, []);

  return (
    <>
      {user ? (
        <>
          <Routes>
            {user?.role === "admin" ? (
              <Route
                path="admin/*"
                element={
                  <AdminLayout userRequest={userRequest} routes={routesAdmin} />
                }
              />
            ) : user?.role === "tailor" ? (
              <Route
                path="admin/*"
                element={
                  <AdminLayout
                    userRequest={userRequest}
                    routes={routesTailor}
                  />
                }
              />
            ) : user?.role === "hairdresser" ? (
              <Route
                path="admin/*"
                element={
                  <AdminLayout
                    userRequest={userRequest}
                    routes={routesHairdress}
                  />
                }
              />
            ) : (
              <Route
                path="admin/*"
                element={
                  <AdminLayout
                    userRequest={userRequest}
                    routes={routesBlogger}
                  />
                }
              />
            )}
            <Route path="/*" element={<Navigate to="/admin" replace />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="/*" element={<Navigate to="auth/sign-in" replace />} />
          </Routes>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default App;
