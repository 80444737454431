import React from "react";

function ViewUser({ handleInfoClose, infoUser, handleChange, save }) {
  return (
    <div
      style={{ backgroundColor: "#000000b3" }}
      className="fixed right-0 top-0 z-50 flex h-screen w-screen items-center justify-center"
    >
      <div className="w-full max-w-2xl">
        <form className="mb-4 rounded bg-white px-8 pb-8 pt-6 shadow-md">
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="full_name"
              >
                Nom complet
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="full_name"
                name="full_name"
                type="text"
                onChange={handleChange}
                value={infoUser.full_name}
                placeholder="full_name"
              />
            </div>
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="email"
              >
                Email
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                value={infoUser.email}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="city"
              >
                Ville
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="city"
                name="city"
                type="text"
                onChange={handleChange}
                value={infoUser.city}
                placeholder="city"
              />
            </div>
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="phone"
              >
                Phone
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="phone"
                name="phone"
                onChange={handleChange}
                type="text"
                value={infoUser.phone}
                placeholder="phone"
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="city"
              >
                Code zip
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="zip"
                name="zip"
                type="text"
                onChange={handleChange}
                value={infoUser.zip}
                placeholder="zip code"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
              type="button"
              onClick={()=>{save()}}
            >
              Save
            </button>
            <button
              onClick={() => handleInfoClose()}
              className="focus:shadow-outline rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none"
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ViewUser;
