import {
  loginFailure,
  loginStart,
  loginSuccess,
  logoutExp,
  logoutNotAdmin,
  registerFailure,
  registerStart,
  registerSuccess,
} from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";

import { toast } from "react-toastify";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    if (
      (res.data.role === "admin" ||
        res.data.role === "tailor" ||
        res.data.role === "hairdresser" ||
        res.data.role === "blogger") &&
      res.data.state === true
    ) {
      dispatch(loginSuccess(res.data));
      toast.success("Content de vous revoir!");
    } else if (res.data.state === false) {
      dispatch(logoutNotAdmin());
      toast.error(
        "Désolé votre compte est désactiver, veuillez contacter l'administration!"
      );
    } else {
      dispatch(logoutNotAdmin());
      toast.error(
        "Désolé vous n'avez pas de compte, veuillez contacter l'administration!"
      );
    }
  } catch (err) {
    dispatch(loginFailure());
    toast.error("Erreur de connexion!");
  }
};

export const register = async (dispatch, user) => {
  dispatch(registerStart());
  try {
    const res = await publicRequest.post("/auth/register", user);
    dispatch(registerSuccess(res.data));
    toast.success("Vous etes incrit avec succès!");
  } catch (err) {
    dispatch(registerFailure());
    toast.error("Une erreur s'est produite, verifie tes informations!");
  }
};

export const checkTokenNow = async (dispatch) => {
  const storedUser = JSON.parse(localStorage.getItem("persist:root"));
  if (JSON.parse(storedUser.user).currentUser) {
    try {
      const res = await userRequest.post("/auth/checktoken");
    } catch (err) {
      dispatch(logoutExp());
      toast.error("Token expirer!!");
    }
  }
};
