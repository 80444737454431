import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Manager from "views/admin/manager";
import Store from "views/admin/store";
import Users from "views/admin/users";
import Calendar from "views/admin/calendar";
import ManagerOrders from "views/admin/orders";


// Icon Imports
import {
  MdManageAccounts,
  MdHome,
  MdOutlineShoppingCart,
  MdPerson,
  MdPeople,
  MdCalendarMonth,
  MdStore,
} from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";

const routes = [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Commandes",
    layout: "/admin",
    path: "commandes",
    icon: <FaHandHoldingDollar className="h-6 w-6" />,
    component: <ManagerOrders />,
    secondary: true,
  },
  {
    name: "Boutique",
    layout: "/admin",
    path: "boutique",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Utilisateurs",
    layout: "/admin",
    path: "utilisateurs",
    icon: <MdPeople className="h-6 w-6" />,
    component: <Users />,
  },
  {
    name: "Staff",
    layout: "/admin",
    path: "managing",
    icon: <MdManageAccounts className="h-6 w-6" />,
    component: <Manager />,
  },
  {
    name: "Articles rendez-vous",
    layout: "/admin",
    path: "articles",
    icon: <MdStore className="h-6 w-6" />,
    component: <Store />,
  },
  {
    name: "Rendez-vous",
    layout: "/admin",
    path: "rendez-vous",
    icon: <MdCalendarMonth className="h-6 w-6" />,
    component: <Calendar />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
];
export default routes;
