export const columnsDataColumns = [
  {
    Header: "NOM COMPLET",
    accessor: "full_name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "VILLE",
    accessor: "city",
  },
  {
    Header: "RÔLE",
    accessor: "role",
  },
  {
    Header: "ACTION",
    accessor: "state",
  },
];

