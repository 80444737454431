import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    /****************Login */
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    /****************Register */
    registerStart: (state) => {
      state.isFetching = true;
    },
    registerSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    registerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    /****************Logout */
    logout: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
      toast.info("A bientôt!");
    },
    logoutExp: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
    },
    logoutNotAdmin: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
    },
  },
});

export const {logoutExp, loginStart, loginSuccess, loginFailure, registerStart,
  registerSuccess,
  registerFailure, logout, logoutNotAdmin} = userSlice.actions;
export default userSlice.reducer;
