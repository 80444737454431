import axios from "axios";

const BASE_URL = process.env.REACT_APP_YOUR_API_URL;
let token=""

const storedUser = JSON.parse(window.localStorage.getItem('persist:root'));
if(JSON.parse(storedUser.user).currentUser){
  token=JSON.parse(storedUser.user).currentUser.accessToken
}

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: process.env.REACT_APP_YOUR_API_URL,
  headers: { token: `Bearer ${token}` },
});

export const uploadImage = axios.create({
  baseURL: process.env.REACT_APP_YOUR_CLOUD_URL,
});
