import { useSelector } from "react-redux";
import Banner from "./components/Banner";
import General from "./components/General";
import Upload from "./components/Upload";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ProfileOverview = () => {
  const [profil, setProfil] = useState("");
  const [edit, setEdit] = useState({});
  const [pass, setPass] = useState({
    lastpassword: "",
    cpassword: "",
    password: "",
  });
  const [savestate, setSavestate] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const handleChange = ({ currentTarget }) => {
    if (!savestate) {
      setSavestate(true);
    }
    const { name, value } = currentTarget;
    setEdit({ ...edit, [name]: value });
  };

  const handlePass = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setPass({ ...pass, [name]: value });
  };

  const save = async () => {
    try {
      const res = await userRequest.put("/users/", edit);
      setEdit({});
      const data = await userRequest.get("/users/my-info");
      setEdit(data.data);
      setSavestate(false);
      toast.success("Information enrégistrer avec succès");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const savePass = async () => {
    if (
      pass.cpassword !== "" ||
      pass.lastpassword !== "" ||
      pass.password !== ""
    ) {
      if (pass.cpassword === pass.password) {
        try {
          const res = await userRequest.put("/auth/password", pass);
          toast.success("Information enrégistrer avec succès");
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        toast.error("Veuillez verifier le mot de passe!");
      }
    } else {
      toast.error("Veuillez remplir tout les champs!");
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const res = await userRequest.get("/users/my-info");
      setProfil(res.data);
      setEdit(res.data);
    };

    getUser();
  }, [user]);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner profil={profil} />
        </div>

        <div className="z-0 col-span-8 lg:!mb-0">
          <Upload
            edit={edit}
            handleChange={handleChange}
            savestate={savestate}
            save={save}
          />
        </div>
      </div>
      {/* all project & ... */}

      <div className="">
        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          <General handlePass={handlePass} savePass={savePass} />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
