import React from "react";

function ViewBlog({ handleInfoClose, product, handleChange, save, user }) {
  return (
    <div
      style={{ backgroundColor: "#000000b3" }}
      className="fixed right-0 top-0 z-50 flex h-screen w-screen items-center justify-center"
    >
      <div className="w-full max-w-2xl">
        <form className="mb-4 rounded bg-white px-8 pb-8 pt-6 shadow-md">
          <div className="rounded pt-6">
            <div className="h-[200px] overflow-hidden">
              <img className="" src={product.img} alt="" />
            </div>
          </div>
          <div className=" rounded pt-6">
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="category"
              >
                Titre
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
                id="title"
                name="title"
                type="text"
                onChange={handleChange}
                value={product.title}
                placeholder="title"
              />
            </div>
          </div>
          <div className=" rounded pt-6">
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="category"
              >
                Prix
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
                id="price"
                name="price"
                type="text"
                onChange={handleChange}
                value={product.price}
                placeholder="price"
              />
            </div>
          </div>
          <div className=" rounded pt-6">
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="title"
              >
                Categorie
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
                id="category"
                name="category"
                type="text"
                value={product.category}
                placeholder="category"
              />
            </div>
          </div>
          <div className=" rounded pb-8 pt-6">
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="decription"
              >
                Blog description
              </label>
              <textarea
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
                id="description"
                name="description"
                onChange={handleChange}
                value={product.description}
                placeholder="description"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            { (user.role === "admin" || user.role === "blogger" ) &&
              <button
              className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
              type="button"
              onClick={() => {save()}}
            >
              Save
            </button>}
            <button
              onClick={() => handleInfoClose()}
              className="focus:shadow-outline rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none"
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ViewBlog;
