export const columnsDataColumns = [
  {
    Header: "IMAGE",
    accessor: "img",
  },
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "PRIX",
    accessor: "price",
  },
  {
    Header: "ACTION",
    accessor: "_id",
  },
];

