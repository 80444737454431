export const columnsDataCheck = [
  {
    Header: "SERVICE",
    accessor: "service",
  },
  {
    Header: "VILLE",
    accessor: "city",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
];
export const columnsDataCheckUser = [
  {
    Header: "NOM COMPLET",
    accessor: "full_name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "VILLE",
    accessor: "city",
  },
  {
    Header: "STATUS",
    accessor: "state",
  },
];

