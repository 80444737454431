import ColumnsTable from "./components/ColumnsTable";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { columnsDataColumns } from "./variables/columnsData";
import ViewUser from "components/infos/ViewUser";

const UsersOverview = () => {
  const [users, setUsers] = useState([]);
  const [info, setInfo] = useState(false);
  const [infoUser, setInfoUser] = useState("");

  const handleInfoClose = () => {
    setInfo(!info);
    setInfoUser("");
  };

  const user = useSelector((state) => state.user.currentUser);
  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const handleInfoOpen = async (id) => {
    try {
      let user = await userRequest.get(`/users/find/${id}`);
      setInfoUser(user.data);
      setInfo(!info);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setInfoUser({ ...infoUser, [name]: value });
  };

  const handleState = async (id) => {
    try {
      await userRequest.put(`/users/state/${id}`);
      let data = await userRequest.get("/users/clients");
      setUsers(data.data);
      toast.success("Statut modifier avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/users/${id}`);
      let data = await userRequest.get("/users/clients");
      setUsers(data.data);
      toast.success("Utilisateur supprimer avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const save = async () => {
    try {
      await userRequest.put("/users/update", infoUser);
      setInfoUser("");
      let data = await userRequest.get("/users/clients");
      setUsers(data.data);
      setInfo(!info);
      toast.success("Information mis a jour avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const res = await userRequest.get("/users/clients");
      setUsers(res.data);
    };

    getUsers();
  }, [user]);

  return (
    <>
      <div className="flex w-full flex-col gap-5">
        <div className="mt-3 flex h-fit w-full flex-col  lg:grid lg:grid-cols-12">
          {/* <div className="col-span-12 mb-3 mt-3 flex h-max items-center justify-evenly rounded-lg bg-white pb-3 pt-3 dark:bg-navy-700 dark:text-white">
            <input
              className="block appearance-none rounded border bg-gray-200 px-2 py-2 leading-tight text-gray-700 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
              type="text"
              placeholder="filtrer le nom"
            />
            <select
              className="block appearance-none rounded border bg-gray-200 px-2 py-2 leading-tight text-gray-700 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
              placeholder="filtrer la ville"
            >
              <option value="">Filtrer par ville</option>
              <option value="">Paris</option>
              <option value="">Toulouse</option>
              <option value="">Nice</option>
            </select>
            <select
              className="block appearance-none rounded border bg-gray-200 px-2 py-2 leading-tight text-gray-700 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
              placeholder="filtrer la ville"
            >
              <option value="">Filtrer par Status</option>
              <option value="">On</option>
              <option value="">Off</option>
            </select>
          </div> */}
          <div className="z-0 w-ful col-span-12 lg:!mb-0 h-{{80vh}}">
            <ColumnsTable
              columnsData={columnsDataColumns}
              tableData={users}
              handleState={handleState}
              handleDelete={handleDelete}
              handleInfoOpen={handleInfoOpen}
            />
          </div>
        </div>
      </div>
      {info && (
        <ViewUser
          handleInfoClose={handleInfoClose}
          infoUser={infoUser}
          handleChange={handleChange}
          save={save}
        />
      )}
    </>
  );
};

export default UsersOverview;
