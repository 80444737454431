import { useState } from "react";
import ColumnsTable from "./components/ColumnsTable";
import Upload from "./components/Upload";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { uploadImage } from "requestMethods";

const ManagerOverview = () => {
  const user = useSelector((state) => state.user.currentUser);

  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const [newBlog, setNewBlog] = useState({
    title: "",
    category: "coiffure",
    description: "",
    img: "",
  });

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setNewBlog({ ...newBlog, [name]: value });
  };

  const save = async () => {
    if (newBlog.img !== "") {
      const formData = new FormData();
      formData.append("file", newBlog.img);
      formData.append("upload_preset", "jtpc9fa3");

      try {
        const response = await uploadImage.post("upload", formData);

        const imageUrl = response.data.secure_url;
        await setNewBlog({ ...newBlog, img: imageUrl });

        const createBlog = async () => {
          try {
            const res = await userRequest.post("/blogs/", {
              title: newBlog.title,
              category: newBlog.category,
              description: newBlog.description,
              img: imageUrl,
            });
            setNewBlog({
              title: "",
              category: "coiffure",
              description: "",
              img: "",
            });
            toast.success("Information enrégistrer avec succès");
          } catch (error) {
            toast.error(error.message);
          }
        };
        createBlog();
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Veuillez ajouter une image");
    }
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5">
        <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
          <div className="z-0 col-span-8 lg:!mb-0 h-{{80vh}}">
            <ColumnsTable
              handleChange={handleChange}
              save={save}
              setNewBlog={setNewBlog}
              newBlog={newBlog}
            />
          </div>
          <div className="col-span-4 h-max lg:!mb-0">
            <Upload
              newBlog={newBlog}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerOverview;
