import React from "react";

function ViewUser({ handleInfoClose, infoOrder }) {
  return (
    <div
      style={{ backgroundColor: "#000000b3" }}
      className="fixed right-0 top-0 z-50 flex h-screen w-screen items-center justify-center"
    >
      <div className="w-full max-w-2xl">
        <form className="mb-4 rounded bg-white px-8 pb-8 pt-6 shadow-md">
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="full_name"
              >
                Nom client
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="full_name"
                name="full_name"
                type="text"
                value={infoOrder?.shipping.name}
                placeholder="full_name"
              />
            </div>
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="email"
              >
                Phone
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="email"
                name="email"
                type="text"
                value={infoOrder?.shipping.phone}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="city"
              >
                Ville
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="city"
                name="city"
                type="text"
                value={infoOrder?.shipping.address.city}
                placeholder="city"
              />
            </div>
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="phone"
              >
                Adresse
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="phone"
                name="phone"
                type="text"
                value={infoOrder?.shipping.address.line1}
                placeholder="phone"
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="w-full px-3 md:mb-0 md:w-1/2">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                for="city"
              >
                Total (euro)
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="zip"
                name="zip"
                type="text"
                value={infoOrder?.total/100}
                placeholder="zip code"
              />
            </div>
          </div>
          
          <ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
          {infoOrder.products.map(product=>(
            <li class="pb-3 sm:pb-4">
              <div class="flex items-center space-x-4 rtl:space-x-reverse">
                <div class="flex-shrink-0">
                  <img
                    class="h-8 w-8 rounded-full"
                    src={product.img}
                    alt="Neil image"
                  />
                </div>
                <div class="min-w-0 flex-1">
                  <p class="truncate text-sm font-medium text-gray-900 dark:text-white">
                  {product.title.slice(0,30)}...
                  </p>
                  <p class="truncate text-sm text-gray-500 dark:text-gray-400">
                    <span>qte: {product.quantity},</span> <span>{product.color && `color: ${product.color} , `}</span> <span>{product.size && `taille: ${product.size} `}</span> 
                  </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                {product.price*product.quantity} €
                </div>
              </div>
            </li>
            ))}
          </ul>
          <div className="flex items-center justify-between">
            <button
              onClick={() => handleInfoClose()}
              className="focus:shadow-outline rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none"
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ViewUser;
