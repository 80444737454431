import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Manager from "views/admin/manager";
import Store from "views/admin/store";
import Users from "views/admin/users";
import Calendar from "views/admin/calendar";


// Icon Imports
import {
  MdManageAccounts,
  MdHome,
  MdOutlineShoppingCart,
  MdPerson,
  MdPeople,
  MdCalendarMonth,
  MdStore,
} from "react-icons/md";

const routesHairdress = [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Coiffures",
    layout: "/admin",
    path: "Coiffures",
    icon: <MdStore className="h-6 w-6" />,
    component: <Store />,
  },
  {
    name: "Rendez-vous",
    layout: "/admin",
    path: "rendez-vous",
    icon: <MdCalendarMonth className="h-6 w-6" />,
    component: <Calendar />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
];

export default routesHairdress;