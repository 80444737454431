import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { useState } from "react";
import Card from "components/card";

const BlogCard = ({ title, image, extra, handleInfoOpen, handleDelete, id }) => {
  const [heart, setHeart] = useState(true);
  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className="relative w-full h-60 overflow-hidden">
          <img
            src={image}
            className="mb-3 w-full rounded-xl 3xl:h-full 3xl:w-full"
            alt=""
          />
        </div>

        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2">
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {`${title.slice(0, 20)}...`}
            </p>
          </div>

          {/* <div className="flex flex-row-reverse md:mt-2 lg:mt-0">
            <span className="z-0 ml-px inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-[#E0E5F2] text-xs text-navy-700 dark:!border-navy-800 dark:bg-gray-800 dark:text-white">
              +5
            </span>
          </div> */}
        </div>

        <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
          <div className="flex">
            
          </div>
          <div className="flex w-full justify-between">
          <button
            onClick={()=>{handleInfoOpen(id)}}
            className="linear rounded-[20px] bg-brand-900 px-4 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Voir
          </button>
          <button
            onClick={()=>{handleDelete(id)}}
            className="linear rounded-[20px] bg-red-700 px-4 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-red-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Delete
          </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BlogCard;
