import { useEffect, useState } from "react";
import ColumnsTable from "./components/ColumnsTable";
import Upload from "./components/Upload";

import { columnsDataColumns } from "./variables/columnsData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { uploadImage } from "requestMethods";

const ManagerOverview = () => {
  const user = useSelector((state) => state.user.currentUser);

  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    img: "",
  });

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const save = async () => {
    if (newProduct.img !== "") {
      const formData = new FormData();
      formData.append("file", newProduct.img);
      formData.append("upload_preset", process.env.REACT_APP_YOUR_CLOUD_PRESET);

      try {
        const response = await uploadImage.post("upload", formData);

        const imageUrl = response.data.secure_url;
        await setNewProduct({ ...newProduct, img: imageUrl });

        const createProduct = async () => {
          try {
              await userRequest.post("/products", {
              name: newProduct.name,
              type: newProduct.type,
              price: newProduct.price,
              description: newProduct.description,
              img: imageUrl,
            });
            setNewProduct({
              name: "",
              type: "",
              price: "",
              description: "",
              img: "",
            });
            const data = await userRequest.get("/products");
            setProducts(data.data);
            toast.success("Produit crée avec succès");
          } catch (error) {
            toast.error(error.message);
          }
        };
        createProduct();
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Veuillez ajouter une image");
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/products/${id}`);
      let data = await userRequest.get("/products/");
      setProducts(data.data);
      toast.success("Produit supprimer avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        if(user.role==="admin"){
          let res = await userRequest.get("/products");
          setProducts(res.data);
        }else if(user.role==="tailor"){
          let res = await userRequest.get("/products?cat=tailor");
          setProducts(res.data);
        }else if(user.role==="hairdresser"){
          let res = await userRequest.get("/products?cat=hairdress");
          setProducts(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getProducts();
  }, [user]);


  return (
    <>
      <div className="flex w-full flex-col gap-5">
        <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
          <div className="z-0 col-span-8 lg:!mb-0 h-{{80vh}}">
            <ColumnsTable
              columnsData={columnsDataColumns}
              tableData={products}
              handleDelete={handleDelete}
            />
          </div>
          <div className="col-span-4 h-max lg:!mb-0">
            <Upload
              handleChange={handleChange}
              save={save}
              user={user}
              newProduct={newProduct}
              setNewProduct={setNewProduct}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerOverview;
