import ColumnsTable from "./components/ColumnsTable";

import {
  columnsDataColumns,
} from "./variables/columnsData";
import tableDataColumns from "../calendar/variables/tableDataColumns.json";

const UsersOverview = () => {
  return (
    <>
      <div className="flex w-full flex-col gap-5">
        <div className="mt-3 flex h-fit w-full flex-col  lg:grid lg:grid-cols-12">
          <div className="z-0 w-ful col-span-12 lg:!mb-0 h-{{80vh}}">
            <ColumnsTable
              columnsData={columnsDataColumns}
              tableData={tableDataColumns}
            />
          </div>
        </div>
      </div>
      
    </>
  );
};

export default UsersOverview;
