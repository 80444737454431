import { useEffect, useState } from "react";
import ColumnsTable from "./components/ColumnsTable";
import Upload from "./components/Upload";

import { columnsDataColumns } from "./variables/columnsData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import ViewUser from "components/infos/ViewUser";

const ManagerOverview = () => {
  const user = useSelector((state) => state.user.currentUser);

  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const [admin, setAdmin] = useState([]);
  const [newAdmin, setNewAdmin] = useState({
    role: "hairdresser",
    city: "Paris",
  });
  const [info, setInfo] = useState(false);
  const [infoUser, setInfoUser] = useState("");


  const handleInfoClose = () => {
    setInfo(!info);
    setInfoUser("");
  };

  const handleInfoOpen = async (id) => {
    try {
      let user = await userRequest.get(`/users/find/${id}`);
      setInfoUser(user.data);
      setInfo(!info);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInfo = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setInfoUser({ ...infoUser, [name]: value });
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setNewAdmin({ ...newAdmin, [name]: value });
  };

  const save = async () => {
    try {
      const res = await userRequest.post("/auth/register/admin", newAdmin);
      setNewAdmin({
        full_name: "",
        email: "",
        phone: "",
        address: "",
        role: "hairdresser",
        city: "Paris",
      });
      let data = await userRequest.get("/users/staff");
      setAdmin(data.data);
      toast.success("Admin ajouter avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const handleState = async (id) => {
    try {
      const res = await userRequest.put(`/users/state/${id}`);
      let data = await userRequest.get("/users/staff");
      setAdmin(data.data);
      toast.success("Statut modifier avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await userRequest.delete(`/users/${id}`);
      let data = await userRequest.get("/users/staff");
      setAdmin(data.data);
      toast.success("Utilisateur supprimer avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

   const update = async () => {
    try {
      await userRequest.put("/users/update", infoUser);
      setInfoUser("");
      let data = await userRequest.get("/users/staff");
      setAdmin(data.data);
      setInfo(!info);
      toast.success("Information mis a jour avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const getAdmin = async () => {
      try {
        let res = await userRequest.get("/users/staff");
        setAdmin(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getAdmin();
  }, [user]);

  console.log(infoUser)

  return (
    <>
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="z-0 col-span-8 lg:!mb-0 h-{{80vh}}">
          <ColumnsTable columnsData={columnsDataColumns} tableData={admin} handleState={handleState} handleDelete={handleDelete} handleInfoOpen={handleInfoOpen}/>
        </div>
        <div className="col-span-4 h-max lg:!mb-0">
          <Upload handleChange={handleChange} save={save} newAdmin={newAdmin} />
        </div>
      </div>
    </div>
    {info && (
        <ViewUser
          handleInfoClose={handleInfoClose}
          infoUser={infoUser}
          handleChange={handleChangeInfo}
          save={update}
        />
      )}
    </>
  );
};

export default ManagerOverview;
