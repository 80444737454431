import Card from "components/card";
import React from "react";

const Upload = ({ handleChange, save, newProduct, setNewProduct, user }) => {
  return (
    <Card className=" h-full w-full  rounded-[20px] bg-white bg-clip-border p-4 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none ">
      <div className="-mx-3 mb-2 flex flex-wrap">
        <div className="md:w-1/1 mb-6 w-full px-3 md:mb-0">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-name"
          >
            Nom produit
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-name"
            type="text"
            name="name"
            onChange={handleChange}
            value={newProduct.name}
            placeholder="Ex. nom coiffure"
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-prix"
          >
            Prix (euro)
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-prix"
            type="text"
            name="price"
            onChange={handleChange}
            value={newProduct.price}
            placeholder="Ex. 25"
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-description"
          >
            Description
          </label>
          <textarea
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-description"
            type="text"
            name="description"
            onChange={handleChange}
            value={newProduct.description}
            placeholder=""
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-type"
          >
            Type
          </label>
          <select
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            name="type"
            onChange={handleChange}
            value={newProduct.type}
            id="grid-type"
          >
            {user.role === "admin" ? (
              <>
                <option value="">Choisi le type</option>
                <option value="hairdress">Coiffure</option>
                <option value="tailor">Couture</option>
              </>
            ) : user.role === "haidersser" ? (
              <>
                <option value="">Choisi le type</option>
                <option value="hairdress">Coiffure</option>
              </>
            ) : (
              <>
                <option value="">Choisi le type</option>
                <option value="tailor">Couture</option>
              </>
            )}
          </select>
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="file"
          >
            Image
          </label>
          <input
            class="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="file"
            type="file"
            name="img"
            accept="image/*"
            onChange={(e) =>
              setNewProduct({ ...newProduct, img: e.target.files[0] })
            }
          />
        </div>
      </div>

      <div className="-mx-3 mb-2 mt-5 flex flex-wrap justify-end">
        <div className="mb-6 flex w-full justify-end px-3 md:mb-0 md:w-1/3">
          <button
            onClick={() => save()}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Ajouter
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Upload;
