import Card from "components/card";
import React from "react";

const ColumnsTable = (props) => {
  const { handleChange, save, setNewBlog, newBlog } = props;

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Veuillez remplir la formulaire
        </div>
        {/* <CardMenu /> */}
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <div className="-mx-3 mb-2 flex flex-wrap">
          <div className="md:w-1/1 mb-6 w-full px-3 md:mb-0">
            <label
              className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
              for="grid-name"
            >
              Titre blog
            </label>
            <input
              className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
              id="grid-name"
              type="text"
              name="title"
              onChange={handleChange}
              value={newBlog.title}
              placeholder="Ex. la couture un art"
            />
          </div>
        </div>
        <div className="-mx-3 mb-2 flex flex-wrap">
          <div className="md:w-1/1 mb-6 w-full px-3 md:mb-0">
          <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="title"
            >
              Categorie blog
            </label>
            <select
              className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              name="category"
              value={newBlog.category}
              id=""
              onChange={handleChange}
            >
              <option value="coiffure">Coiffure</option>
              <option value="couture">Couture</option>
            </select>
          </div>
        </div>
        <div className="-mx-3 mb-2 flex flex-wrap">
          <div className="md:w-1/1 mb-6 w-full px-3 md:mb-0">
          <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="file"
            >
              Blog image
            </label>
            <input
              className="focus:shadow-outline mb-3 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              id="file"
              type="file"
              name="img"
              accept="image/*"
              onChange={(e) => setNewBlog({ ...newBlog, img: e.target.files[0] })}
            />
          </div>
        </div>
        <div className="-mx-3 mb-2 flex flex-wrap">
          <div className="md:w-1/1 mb-6 w-full px-3 md:mb-0">
          <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="decription"
            >
              Blog description
            </label>
            <textarea
              className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              id="decription"
              type="text"
              name="description"
              onChange={handleChange}
              value={newBlog.description}
              placeholder="description"
            />
          </div>
        </div>
        <button
          onClick={() => save()}
          className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >
          Envoyer
        </button>
      </div>
    </Card>
  );
};

export default ColumnsTable;
