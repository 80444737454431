import Card from "components/card";
import React from "react";

const Upload = ({ handleChange, save, newAdmin }) => {
  return (
    <Card className=" h-full w-full  rounded-[20px] bg-white bg-clip-border p-4 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none ">
      <div className="-mx-3 mb-2 flex flex-wrap">
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-name"
          >
            Nom complet
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-name"
            type="text"
            name="full_name"
            onChange={handleChange}
            value={newAdmin.full_name}
            placeholder="John Doe"
          />
        </div>
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-name"
          >
            Email
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-name"
            type="text"
            name="email"
            onChange={handleChange}
            value={newAdmin.email}
            placeholder="John@Doe.com"
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-name"
          >
            Numéro de téléphone
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-name"
            type="phone"
            name="phone"
            onChange={handleChange}
            value={newAdmin.phone}
            placeholder="Ex. 06 12 34 56 78"
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-password"
          >
            Adresse
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-password"
            type="text"
            name="address"
            onChange={handleChange}
            value={newAdmin.address}
            placeholder="exp: paris .. rue .."
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="w-full px-3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-password"
          >
            Rôle
          </label>
          <select
            className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            name="role"
            onChange={handleChange}
            value={newAdmin.role}
            id=""
          >
            <option value="hairdresser">Coiffeur</option>
            <option value="tailor">Couturier</option>
          </select>
        </div>
      </div>
      <div className="-mx-3 mb-2 flex flex-wrap">
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-state"
          >
            Ville
          </label>
          <div className="relative">
            <select
              className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 pr-8 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
              id="grid-state"
              name="city"
              onChange={handleChange}
              value={newAdmin.city}
            >
              <option value="Paris">Paris</option>
              <option value="Toulouse">Toulouse</option>
              <option value="Nice">Nice</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-zip"
          >
            Code postal
          </label>
          <input
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-zip"
            type="text"
            name="zip"
            onChange={handleChange}
            value={newAdmin.zip}
            placeholder="90210"
          />
        </div>
      </div>
      <div className="-mx-3 mb-2 mt-5 flex flex-wrap justify-end">
        <div className="mb-6 flex w-full justify-end px-3 md:mb-0 md:w-1/3">
          <button
            onClick={() => save()}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Ajouter
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Upload;
