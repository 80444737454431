import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FaCheckCircle , FaTimesCircle , FaEye, FaTrash } from "react-icons/fa";

const ColumnsTable = (props) => {
  const { columnsData, tableData, handleState, handleDelete, handleInfoOpen } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Liste des membres du staff
        </div>
        <CardMenu />
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pb-[10px] pr-14 text-start dark:!border-navy-700"
                  >
                    <div className="flex w-full justify-between pr-10 text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data;
                    if (cell.column.Header === "NOM CLIENT") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "PHONE") {
                      data = (
                        <p className="mr-[10px] text-sm font-semibold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "ADRESSE") {
                      data = (
                        <p className="mr-[10px] text-sm font-semibold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "TOTAL") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value / 100} €
                        </p>
                      );
                    } else if (cell.column.Header === "PAIEMENT") {
                      data = cell.value === "paid" && (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          <span class="me-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
                            {cell.value}
                          </span>
                        </p>
                      );
                    } else if (cell.column.Header === "LIVRAISON") {
                      data =
                        cell.value === "pending" ? (
                          <p className="text-sm font-bold text-navy-700 dark:text-white flex">
                            <span class="me-2 rounded bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300">
                              {cell.value}
                            </span>

                            <>
                                <FaTimesCircle 
                                  onClick={() => handleState(row.original._id, "cancel")}
                                  className="cursor-pointer text-xl text-red-600"
                                />
                                <FaCheckCircle 
                                  onClick={() => handleState(row.original._id, "delivered")}
                                  className="cursor-pointer text-xl text-green-600 ml-1"
                                />
                              </>
                          </p>
                        ) : cell.value === "delivered" ? (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            <span class="me-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
                              {cell.value}
                            </span>
                          </p>
                        ) : (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            <span class="me-2 rounded bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800 dark:bg-red-900 dark:text-red-300">
                              {cell.value}
                            </span>
                          </p>
                        );
                    } else if (cell.column.Header === "ACTION") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          <div className="flex items-center">
                            <FaEye
                              onClick={() => handleInfoOpen(row.original._id)}
                              className="ml-4 cursor-pointer text-sm text-navy-600"
                            />{" "}
                            <FaTrash
                              onClick={() => handleDelete(row.original._id)}
                              className="ml-4 cursor-pointer text-sm text-red-600"
                            />
                          </div>
                        </p>
                      );
                    }
                    return (
                      <td
                        className="pb-[20px] pt-[14px] sm:text-[10px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ColumnsTable;
