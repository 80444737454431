import { useEffect, useState } from "react";
import ColumnsTable from "./components/ColumnsTable";
import Upload from "./components/Upload";

import { columnsDataColumns } from "./variables/columnsData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import ViewOrder from "components/infos/ViewOrder";

const ManagerOrders = () => {
  const user = useSelector((state) => state.user.currentUser);

  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const [orders, setOrders] = useState([]);
  const [info, setInfo] = useState(false);
  const [infoOrder, setInfoOrder] = useState("");


  const handleInfoClose = () => {
    setInfo(!info);
    setInfoOrder("");
  };

  const handleInfoOpen = async (id) => {
    try {
      let order = await userRequest.get(`/orders/${id}`);
      setInfoOrder(order.data);
      setInfo(!info);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInfo = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setInfoOrder({ ...infoOrder, [name]: value });
  };


  

  const handleState = async (id, status) => {
    try {
      const res = await userRequest.put(`/orders/state/${id}`, {status});
      let data = await userRequest.get("/orders");
      setOrders(data.data);
      toast.success("Statut modifier avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await userRequest.delete(`/orders/${id}`);
      let data = await userRequest.get("/orders");
      setOrders(data.data);
      toast.success("Utilisateur supprimer avec succès!");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  

  useEffect(() => {
    const getOrders = async () => {
      try {
        let res = await userRequest.get("/orders");
        setOrders(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getOrders();
  }, [user]);


  return (
    <>
    <div className="flex w-full flex-col gap-5">
      {/* <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12"> */}
        <div className="z-0 col-span-8 lg:!mb-0 h-{{80vh}}  mt-5">
          <ColumnsTable columnsData={columnsDataColumns} tableData={orders} handleState={handleState} handleDelete={handleDelete} handleInfoOpen={handleInfoOpen}/>
        </div>
        {/* <div className="col-span-4 h-max lg:!mb-0">
          <Upload handleChange={handleChange} save={save} newAdmin={newAdmin} />
        </div> */}
      </div>
    {/* </div> */}
    {info && (
        <ViewOrder
          handleInfoClose={handleInfoClose}
          infoOrder={infoOrder}
        />
      )}
    </>
  );
};

export default ManagerOrders;
