export const columnsDataColumns = [
  {
    Header: "NOM CLIENT",
    accessor: "shipping.name",
  },
  {
    Header: "PHONE",
    accessor: "shipping.phone",
  },
  {
    Header: "ADRESSE",
    accessor: "shipping.address.line1",
  },
  {
    Header: "TOTAL",
    accessor: "total",
  },
  {
    Header: "PAIEMENT",
    accessor: "payment_status",
  },
  {
    Header: "LIVRAISON",
    accessor: "delivery_status",
  },
  {
    Header: "ACTION",
    accessor: "",
  },
];

