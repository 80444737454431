import Card from "components/card";
import React, { useState } from "react";

function TopCreatorTable({
  setSelectedColors,
  selectedColors,
  setSelectedSizes,
  selectedSizes,
  handleChange,
  newProduct,
  save,
  setNewProduct,
}) {
  const size = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
  const color = [
    { name: "red", value: "red" },
    { name: "gray", value: "gray" },
    { name: "blue", value: "blue" },
    { name: "wine", value: "#722f37" },
    { name: "black", value: "black" },
    { name: "navy", value: "navy" },
    { name: "orange", value: "orange" },
    { name: "brown", value: "brown" },
    { name: "ivory", value: "ivory" },
    { name: "khaki", value: "khaki" },
    { name: "purple", value: "purple" },
    { name: "taupe", value: "#463f32" },
    { name: "forest", value: "#124712" },
  ];

  const [typeSize, setTypeSize] = useState(size);
  const [typeColor, setTypeColor] = useState(color);

  const removeSize = (sizeToRemove) => {
    setSelectedSizes(selectedSizes.filter((size) => size !== sizeToRemove));
  };

  const removeColor = (colorToRemove) => {
    setSelectedColors(
      selectedColors.filter((color) => color !== colorToRemove)
    );
  };

  const handleSizeSelect = (event) => {
    const size = event.target.value;
    if (size && !selectedSizes.includes(size)) {
      setSelectedSizes([...selectedSizes, size]);
    }
  };
  const handleColorSelect = (event) => {
    const color = event.target.value;
    if (color && !selectedColors.includes(color)) {
      setSelectedColors([...selectedColors, color]);
    }
  };

  return (
    <Card extra={"h-[600px] w-full"}>
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Ajouter produit
        </h4>
        <button
          onClick={() => save()}
          className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >
          Envoyer
        </button>
      </div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <div className="mb-4 mt-6 rounded px-8 pb-8 pt-6">
          <div className="mb-4">
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="title"
            >
              Article titre
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              id="title"
              type="text"
              name="title"
              onChange={handleChange}
              value={newProduct.title}
              placeholder="title"
            />
          </div>
          <div className="mb-4">
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="title"
            >
              Prix unitaire
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              id="price"
              type="text"
              name="price"
              onChange={handleChange}
              value={newProduct.price}
              placeholder="price"
            />
          </div>
          <div className="mb-4">
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="title"
            >
              Categorie blog
            </label>
            <select
              className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              name="category"
              id=""
              onChange={handleChange}
              value={newProduct.category}
            >
              <option value="soin">Produit Soin</option>
              <option value="costume">Costume</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="decription"
            >
              Blog description
            </label>
            <textarea
              className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              id="decription"
              type="text"
              name="description"
              onChange={handleChange}
              value={newProduct.description}
              placeholder="description"
            />
          </div>
          <div className="mb-4">
            <div className="list_select mb-2">
              {selectedSizes.length > 0 ? (
                selectedSizes.map((size) => (
                  <span
                    className="size_item mr-2 cursor-pointer rounded border-[1px] border-solid border-gray-800 pl-[2px] pr-[2px]"
                    key={size}
                    onClick={() => removeSize(size)}
                  >
                    {size}
                  </span>
                ))
              ) : (
                <span className="no_item">Aucune Taille sélectionnée</span>
              )}
            </div>
            <select
              name="size"
              id=""
              onChange={handleSizeSelect}
              className="focus:shadow-outline mb-3 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
            >
              <option value="default">Choisi le type ...</option>
              {typeSize?.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <div className="list_select_color flex">
              {selectedColors.length > 0 ? (
                selectedColors.map((color) => (
                  <span
                    className={`size_item mb-1 mr-1`}
                    key={color}
                    style={{
                      backgroundColor: color.toLowerCase(),
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      border: "solid 1px black",
                    }}
                    onClick={() => removeColor(color)}
                  ></span>
                ))
              ) : (
                <span className="no_item">Aucune couleur sélectionnée</span>
              )}
            </div>
            <select
              name="size"
              id=""
              onChange={handleColorSelect}
              className="focus:shadow-outline mb-3 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
            >
              <option value="default">Choisi le type ...</option>
              {typeColor?.map((color) => (
                <option key={color} value={color.value}>
                  {color.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              for="file"
            >
              Blog image
            </label>
            <input
              className="focus:shadow-outline mb-3 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:bg-navy-700"
              id="img"
              type="file"
              name="img"
              accept="image/*"
              onChange={(e) =>
                setNewProduct({ ...newProduct, img: e.target.files[0] })
              }
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TopCreatorTable;
