import Card from "components/card";
import React from "react";

const General = ({handlePass, savePass}) => {
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mb-3 mt-2 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Modifier votre mot de passe
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          Pour modifier votre mot de passe veuillez remplir la formulaire
          suivante et envoyer
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-1 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-3 text-sm text-gray-700">
            Entrer votre mot de passe actuel
          </p>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            type="password"
            name="lastpassword"
            onChange={handlePass}
            placeholder="mot de passe actuelle"
          />
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-3 text-sm text-gray-700">
            Entrer votre nouveau mot de passe
          </p>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            type="password"
            name="password"
            onChange={handlePass}
            placeholder="nouveau mot de passe"
          />
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-3 text-sm text-gray-700">
            Confirmer votre mot de passe
          </p>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            type="password"
            name="cpassword"
            onChange={handlePass}
            placeholder="confirmer le mot de passe"
          />
        </div>
        <div className="-mx-3 mb-2 mt-5 flex flex-wrap justify-end">
          <div className="mb-6 flex w-full justify-end px-3 md:mb-0 md:w-1/3">
            <button onClick={()=>{savePass()}} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default General;
