import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import { MdOutlineClose, MdOutlineCancel, MdSearch } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";
import { BsCashCoin } from "react-icons/bs";
import { toast } from "react-toastify";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import axios from "axios";

const ColumnsTable = () => {
  const [list, setList] = useState(false);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [state, setState] = useState("");
  moment.locale("fr");

  const user = useSelector((state) => state.user.currentUser);
  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);

  const activeList = async (state) => {
    setState(state);
    if (user.role === "admin") {
      if (state === "all") {
        let res = await userRequest.get(`books`);
        setData(res.data);
        setCurrentData(res.data);
        setList(true);
      } else {
        let res = await userRequest.get(`books?cat=${state}`);
        setData(res.data);
        setCurrentData(res.data);
        setList(true);
      }
    } else {
      if (state === "all") {
        let res = await userRequest.get(`books/staff/${user._id}`);
        setData(res.data);
        setList(true);
      } else {
        let res = await userRequest.get(`books/staff/${user._id}?cat=${state}`);
        setData(res.data);
        setList(true);
      }
    }
  };

  const changeStatus = async (status, id) => {
    try {
      if (user.role === "admin") {
        await userRequest.put(`books/${id}`, { status });
        if (state === "all") {
          let res = await userRequest.get(`books`);
          setData(res.data);
          setCurrentData(res.data);
        } else {
          let res = await userRequest.get(`books?cat=${state}`);
          setData(res.data);
          setCurrentData(res.data);
        }
        toast.success("Information enrégistrer avec succès");
      } else {
        await userRequest.put(`books/staff/${id}`, { status });
        if (state === "all") {
          let res = await userRequest.get(`books/staff/${user._id}`);
          setData(res.data);
        } else {
          let res = await userRequest.get(
            `books/staff/${user._id}?cat=${state}`
          );
          setData(res.data);
        }
        toast.success("Information enrégistrer avec succès");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFilter = () => {
    if (filter.service && filter.user) {
      setCurrentData(
        data?.filter(
          (item) =>
            item.service
              .toLowerCase()
              .includes(filter.service?.toLowerCase()) &&
            item.user_id.toLowerCase().includes(filter.user?.toLowerCase())
        )
      );
    } else if (filter.service) {
      setCurrentData(
        data?.filter((item) =>
          item.service.toLowerCase().includes(filter.service?.toLowerCase())
        )
      );
    } else if (filter.user) {
      setCurrentData(
        data?.filter((item) =>
          item.user_id.toLowerCase().includes(filter.user?.toLowerCase())
        )
      );
    }
  };

  const handleChange = async ({ currentTarget }) => {
    const { name, value } = await currentTarget;
    await setFilter({ ...filter, [name]: value });
  };

  const closeList = () => {
    setList(false);
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        if (user.role === "admin") {
          const res = await userRequest.get("books");
          const users = await userRequest.get("users/staff");
          setUsers(users.data);
          setEvents(
            res.data.map((donnee) => ({
              title: `${donnee.service==="tailor"?"Couture":"Coiffure"} (${donnee.client.full_name}, ville: ${donnee.city}, ${donnee.client.address})`,
              start: new Date(moment(donnee.date, "YYYY-MM-DD HH:mm:ss")),
              end: new Date(
                moment(donnee.date, "YYYY-MM-DD HH:mm:ss").add(1, "hours")
              ),
            }))
          );
        } else {
          const res = await userRequest.get(`books/staff/${user._id}`);

          setEvents(
            res.data.map((donnee) => ({
              title: `${donnee.service==="tailor"?"Couture":"Coiffure"} (${donnee.client.full_name}, ville: ${donnee.city}, ${donnee.client.address})`,
              start: new Date(moment(donnee.date, "YYYY-MM-DD HH:mm:ss")),
              end: new Date(
                moment(donnee.date, "YYYY-MM-DD HH:mm:ss").add(1, "hours")
              ),
            }))
          );
        }
      } catch {}
    };
    getBookings();
  }, [user]);

  return (
    <>
      <Card extra={"w-full pb-10 p-4 h-full"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Rendez-vous table
          </div>
          <CardMenu activeList={activeList} />
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 450 }}
          />
        </div>
      </Card>
      {list && (
        <div className="fixed right-0 top-0 z-50 flex h-full w-full items-center justify-center bg-[#000000b5]">
          <Card extra={"w-4/5 pb-10 p-4 h-2/4"}>
            <header className="relative flex items-center justify-between">
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                Liste de rendez-vous
              </div>
              <div className="flex items-center">
                {user.role === "admin" && (
                  <>
                    <select
                      onChange={handleChange}
                      className="mr-4 rounded-sm border-[1px] px-4 py-1 "
                      name="service"
                      id=""
                    >
                      <option value="">Filtrer par service</option>
                      <option value="hairdress">Coiffure</option>
                      <option value="tailor">Couture</option>
                    </select>
                    <select
                      onChange={handleChange}
                      className="mr-4 rounded-sm border-[1px] px-4 py-1 "
                      name="user"
                      id=""
                    >
                      <option value="id">Filtrer par user</option>
                      {users?.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.full_name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="mr-4 rounded-sm border-[1px] px-4 py-1"
                      onClick={handleFilter}
                    >
                      <MdSearch />
                    </button>
                  </>
                )}
                <MdOutlineClose onClick={closeList} />
              </div>
            </header>
            <div className="h-[40vh] flex  overflow-auto">
              <table className="table-auto w-full text-left">
                <thead className="border-b-[1px] border-b-gray-800 pb-4">
                  <tr>
                    <th>Client</th>
                    <th>Date</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {user.role === "admin"
                    ? currentData?.map((item) => (
                        <tr className="mb-3 border-b">
                          <td>{item.client.full_name}</td>
                          <td>{item.date}</td>
                          <td>{item.client.phone}</td>
                          <td>
                            {item.status === "pending" ? (
                              <div className="w-24 rounded-md bg-orange-300 px-1 text-center text-orange-500">
                                {item.status}
                              </div>
                            ) : item.status === "confirmed" ? (
                              <div className="w-24 rounded-md bg-blue-300 px-1 text-center text-blue-500">
                                {item.status}
                              </div>
                            ) : item.status === "paid" ? (
                              <div className="w-24 rounded-md bg-green-300 px-1 text-center text-green-500">
                                {item.status}
                              </div>
                            ) : (
                              <div className="w-24 rounded-md bg-red-300 px-1 text-center text-red-500">
                                {item.status}
                              </div>
                            )}
                          </td>
                          <td>
                            {item.status === "pending" ? (
                              <div className="flex ">
                                <MdOutlineCancel className="mr-4 cursor-pointer  text-red-500 " />
                                <GiConfirmed
                                  onClick={() =>
                                    changeStatus("confirmed", item._id)
                                  }
                                  className="mr-4 cursor-pointer text-green-500 "
                                />
                              </div>
                            ) : item.status === "confirmed" ? (
                              user.role === "admin" ? (
                                <div>
                                  <BsCashCoin
                                    onClick={() =>
                                      changeStatus("paid", item._id)
                                    }
                                    className="mr-4 cursor-pointer text-blue-500 "
                                  />
                                </div>
                              ) : (
                                <p>pas d'action disponible</p>
                              )
                            ) : (
                              <p>pas d'action disponible</p>
                            )}
                          </td>
                        </tr>
                      ))
                    : data?.map((item) => (
                        <tr className="mb-3 border-b">
                          <td>{item.client.full_name}</td>
                          <td>{item.date}</td>
                          <td>{item.client.phone}</td>
                          <td>
                            {item.status === "pending" ? (
                              <div className="w-24 rounded-md bg-orange-300 px-1 text-center text-orange-500">
                                {item.status}
                              </div>
                            ) : item.status === "confirmed" ? (
                              <div className="w-24 rounded-md bg-blue-300 px-1 text-center text-blue-500">
                                {item.status}
                              </div>
                            ) : item.status === "paid" ? (
                              <div className="w-24 rounded-md bg-green-300 px-1 text-center text-green-500">
                                {item.status}
                              </div>
                            ) : (
                              <div className="w-24 rounded-md bg-red-300 px-1 text-center text-red-500">
                                {item.status}
                              </div>
                            )}
                          </td>
                          <td>
                            {item.status === "pending" ? (
                              <div className="flex ">
                                <MdOutlineCancel
                                  onClick={() =>
                                    changeStatus("canceled", item._id)
                                  }
                                  className="mr-4 cursor-pointer  text-red-500 "
                                />
                                <GiConfirmed
                                  onClick={() =>
                                    changeStatus("confirmed", item._id)
                                  }
                                  className="mr-4 cursor-pointer text-green-500 "
                                />
                              </div>
                            ) : item.status === "confirmed" ? (
                              user.role === "admin" ? (
                                <div>
                                  <BsCashCoin className="mr-4 cursor-pointer text-blue-500 " />
                                </div>
                              ) : (
                                <p>pas d'action disponible</p>
                              )
                            ) : (
                              <p>pas d'action disponible</p>
                            )}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default ColumnsTable;
