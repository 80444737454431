import PieChart from "components/charts/PieChart";
import { pieChartData,pieChartOptionsStaff, pieChartOptions } from "variables/charts";
import Card from "components/card";
import { useSelector } from "react-redux";

const PieChartCard = ({data}) => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Ce mois
          </h4>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart options={user.role==="admin" ?pieChartOptions:pieChartOptionsStaff} series={data} />
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            {user.role==="admin"?(
            <p className="ml-1 text-sm font-normal text-gray-600">Couture</p>
            ):(
            <p className="ml-1 text-sm font-normal text-gray-600">Encaissé</p>
            )}
          </div>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            {user.role==="admin"?(
            <p className="ml-1 text-sm font-normal text-gray-600">Coiffure</p>
            ):(
            <p className="ml-1 text-sm font-normal text-gray-600">Total</p>
            )}
            
          </div>
        </div>
      </div>
    </Card>
    
  );
};

export default PieChartCard;
