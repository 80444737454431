import React from "react";
import ReactMarkdown from 'react-markdown'

const Upload = ({ newBlog }) => {
  return (
    <div className="h-full w-full  rounded-[20px] bg-white bg-clip-border p-4  shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none ">
      <h1>Title: {newBlog.title}</h1>
      <h1>Category: {newBlog.category}</h1>
      <ReactMarkdown>{newBlog.description}</ReactMarkdown>
    </div>
  );
};

export default Upload;
