import { MdFileUpload } from "react-icons/md";
import Card from "components/card";
import React from "react";

const Upload = ({ edit, handleChange, savestate, save }) => {
  return (
    <Card className=" h-full w-full  rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none ">
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-first-name"
          >
            Nom complet
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-first-name"
            type="text"
            name="full_name"
            onChange={handleChange}
            value={edit.full_name}
          />
          <p className="text-xs italic text-red-500"></p>
        </div>
        <div className="w-full px-3 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-last-name"
          >
            Email
          </label>
          <input
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-last-name"
            type="text"
            name="email"
            value={edit.email}
          />
        </div>
      </div>
      <div className="-mx-3 mb-6 flex flex-wrap">
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-first-phone"
          >
            Phone
          </label>
          <input
            className="mb-3 block w-full appearance-none rounded border bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-first-phone"
            type="text"
            name="phone"
            onChange={handleChange}
            value={edit.phone}
          />
          <p className="text-xs italic text-red-500"></p>
        </div>
        <div className="w-full px-3 md:w-1/2">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-last-adresse"
          >
            Adresse
          </label>
          <input
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-last-adresse"
            type="text"
            name="adresse"
            onChange={handleChange}
            placeholder="exp: paris .. rue .."
          />
        </div>
      </div>
      <div className="-mx-3 mb-2 flex flex-wrap">
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-city"
          >
            Ville
          </label>
          <input
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-city"
            type="text"
            name="city"
            onChange={handleChange}
            placeholder="Paris"
          />
        </div>
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-state"
          >
            Etat
          </label>
          <div className="relative">
            <select
              className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 pr-8 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
              id="grid-state"
              name="Etat"
              onChange={handleChange}
            >
              <option>Paris</option>
              <option>Toulouse</option>
              <option>Nice</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="mb-6 w-full px-3 md:mb-0 md:w-1/3">
          <label
            className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
            for="grid-zip"
          >
            Code postal
          </label>
          <input
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none dark:border-gray-700 dark:bg-navy-700"
            id="grid-zip"
            type="text"
            name="code_postal"
            onChange={handleChange}
            placeholder="90210"
          />
        </div>
      </div>
      <div className="-mx-3 mb-2 mt-5 flex flex-wrap justify-end">
        <div className="mb-6 flex w-full justify-end px-3 md:mb-0 md:w-1/3">
          {savestate ? (
            <button
              onClick={() => save()}
              className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              Enrégistrer
            </button>
          ) : (
            <button
              disabled
              onClick={() => console.log("ok")}
              className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              Enrégistrer
            </button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default Upload;
