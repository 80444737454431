export const columnsDataColumns = [
  {
    Header: "NOM COMPLET",
    accessor: "full_name",
  },
  {
    Header: "ADRESSE EMAIL",
    accessor: "email",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "VILLE",
    accessor: "city",
  },
  {
    Header: "DATE",
    accessor: "createdAt",
  },
  {
    Header: "ACTION",
    accessor: "state",
  },
];
