import React from "react";

// Admin Imports
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import AddBlog from "views/admin/AddBlog";


// Icon Imports
import {
  MdOutlineShoppingCart,
  MdPerson,
  MdStore,
} from "react-icons/md";

const routesBlogger = [
  {
    name: "Blogs",
    layout: "/admin",
    path: "default",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Add blog",
    layout: "/admin",
    path: "newblog",
    icon: <MdStore className="h-6 w-6" />,
    component: <AddBlog />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
];

export default routesBlogger;