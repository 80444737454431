import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";
import TopCreatorTable from "./components/TableTopCreators";
import BlogCard from "components/card/BlogCard";
import { toast } from "react-toastify";
import { uploadImage } from "requestMethods";
import ViewBlog from "components/infos/ViewBlog";

const Marketplace = () => {
  const [type, setType] = useState("");
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [info, setInfo] = useState(false);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [newProduct, setNewProduct] = useState({
    title: "",
    price: "",
    category: "soin",
    description: "",
    img: "",
  });
  const [filteredProducts, setFilteredProducts] = useState([]);

  const user = useSelector((state) => state.user.currentUser);
  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const handleInfoClose = () => {
    setInfo(!info);
    setProduct("");
  };

  const handleInfoOpen = async (id) => {
    try {
      let product = await userRequest.get(`/shops/${id}`);
      setProduct(product.data);
      setInfo(!info);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/shops/${id}`);
      const data = await userRequest.get(`/shops`);
      setProducts(data.data);
      toast.success("Produit supprimer avec succès!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleChangeInfo = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setProduct({ ...product, [name]: value });
  };


  const saveInfo = async () => {
    try {
      await userRequest.put(`/shops/${product._id}`, product);
      const data = await userRequest.get(`/shops`);
      setProducts(data.data);
      setInfo(!info);
      setProduct("");
      toast.success("Information mis a jour avec succès");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const save = async () => {
    if (newProduct.img !== "") {
      const formData = new FormData();
      formData.append("file", newProduct.img);
      formData.append("upload_preset", process.env.REACT_APP_YOUR_CLOUD_PRESET);

      try {
        const response = await uploadImage.post("upload", formData);

        const imageUrl = response.data.secure_url;
        await setNewProduct({ ...newProduct, img: imageUrl });

        const createproduct = async () => {
          try {
            const res = await userRequest.post("/shops/", {
              title: newProduct.title,
              price: newProduct.price,
              sizes: selectedSizes,
              colors: selectedColors,
              category: newProduct.category,
              description: newProduct.description,
              img: imageUrl,
            });
            setNewProduct({
              title: "",
              price: "",
              category: "coiffure",
              description: "",
              img: "",
            });
            const data = await userRequest.get("/shops");
            setProducts(data.data);
            toast.success("Information enrégistrer avec succès");
          } catch (error) {
            toast.error(error.message);
          }
        };
        createproduct();
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Veuillez ajouter une image");
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      const res = await userRequest.get("/shops");
      setProducts(res.data);
      setFilteredProducts(res.data);
    };

    getProducts();
  }, [user]);

  useEffect(() => {
    // Filtrer les produits en fonction de type et title
    const filtered = products.filter((product) => {
      return (
        (type ? product.category === type : true)
      );
    });
    setFilteredProducts(filtered);
  }, [type, products]);

  return (
    <>
      <div className={user.role === "admin"?`mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3`:"mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1"}>
        <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
          {/* product Banner */}
          {/* <Banner /> */}

          {/* product Header */}
          <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
            <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
              Liste des articles de boutique
            </h4>
            <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
              <li>
              <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href="#" onClick={() => setType("")}
                >
                  Tout
                </a>
              </li>
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href="#" onClick={() => setType("soin")}
                >
                  Soin chéveux
                </a>
              </li>
              <li>
                <a
                  className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                  href="#" onClick={() => setType("costume")}
                >Costume
                </a>
              </li>
            </ul>
          </div>

          {/* product trending card */}
          <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
            {filteredProducts.map((item) => (
              <BlogCard
                key={item._id}
                id={item._id}
                title={item.title}
                image={item.img}
                handleInfoOpen={handleInfoOpen}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        </div>

        {/* right side section */}

        {user.role === "admin" && (
          <div className="sticky top-0 col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
            <TopCreatorTable
              handleChange={handleChange}
              newProduct={newProduct}
              setNewProduct={setNewProduct}
              setSelectedSizes={setSelectedSizes}
              setSelectedColors={setSelectedColors}
              selectedColors={selectedColors}
              selectedSizes={selectedSizes}
              save={save}
              extra="mb-5"
              tableData={tableDataTopCreators}
              columnsData={tableColumnsTopCreators}
            />
            {/* <HistoryCard /> */}
          </div>
        )}
      </div>
      {info && (
        <ViewBlog
          handleInfoClose={handleInfoClose}
          product={product}
          handleChange={handleChangeInfo}
          save={saveInfo}
          user={user}
        />
      )}
    </>
  );
};

export default Marketplace;
