import MiniCalendar from "components/calendar/MiniCalendar";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import {
  columnsDataCheck,
  columnsDataCheckUser,
} from "./variables/columnsData";

import Widget from "components/widget/Widget";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import ColumnsTable from "./components/ColumnsTable";
import ColumnsTableBook from "./components/ColumnsTableBook";

const Dashboard = () => {
  const [overview, setOverview] = useState("");
  const [stats, setStats] = useState([]);

  const user = useSelector((state) => state.user.currentUser);

  const userRequest = axios.create({
    baseURL: process.env.REACT_APP_YOUR_API_URL,
    headers: { token: `Bearer ${user?.accessToken}` },
  });

  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Juil",
      "Aoû",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  useEffect(() => {
    const getOverview = async () => {
      if (user.role === "admin") {
        try {
          let res = await userRequest.get("/overviews");
          setOverview(res.data);
          res.data.bookPerMonth.map((item) =>
            setStats((prev) => [
              ...prev,
              {
                name: MONTHS[item._id - 1],
                coiffure: item.coiffure,
                couture: item.couture,
              },
            ])
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let res = await userRequest.get(`/overviews/staff/${user._id}`);
          setOverview(res.data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    getOverview();
  }, [user]);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {user.role === "admin" ? (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Gains prév"}
              subtitle={`${overview?.earnings} €`}
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Encaissé"}
              subtitle={`${overview?.balance} €`}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Rendez-vous"}
              subtitle={`${overview?.totalAppointments}`}
            />
            <Widget
              icon={<MdDashboard className="h-6 w-6" />}
              title={"Rendez-vous confirmés"}
              subtitle={`${overview?.validatedAppointments}`}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Rendez-vous du mois"}
              subtitle={`${overview?.monthAppointments}`}
            />
            <Widget
              icon={<IoMdHome className="h-6 w-6" />}
              title={"Rendez-vous(jour)"}
              subtitle={`${overview?.dayAppointments}`}
            />
          </>
        ) : (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Rendez-vous"}
              subtitle={`${overview?.bookCount}`}
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Rendez-vous payés"}
              subtitle={`${overview?.bookPaidCount}`}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Rendez-vous annuler"}
              subtitle={`${overview?.bookCanceledCount}`}
            />
          </>
        )}
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* <TotalSpent monthEarn={overview?.monthEarnings} data={overview?.pie} /> */}
        {overview?.pie && (
          <PieChartCard
            monthEarn={overview?.monthEarnings}
            data={overview?.pie}
          />
        )}
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        {user.role === "admin" ? (
          overview?.lastAppointments && (
            <ColumnsTableBook
              columnsData={columnsDataCheck}
              tableData={overview?.lastAppointments}
            />
          )
        ) : (
          <MiniCalendar />
        )}
      </div>

      {/* Tables & Charts */}

      <div className="cal_extra mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {overview?.lastAppointments && (
          <ColumnsTable
            columnsData={columnsDataCheckUser}
            tableData={overview?.lastUsers}
          />
        )}
        {/* Calendar */}

        {/* <TaskCard /> */}
        {user.role === "admin" && <MiniCalendar />}
      </div>
    </div>
  );
};

export default Dashboard;
